import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_PROJECTS_DATA = 'GET_PROJECTS_DATA'
const GET_PROJECTS_DATA_MORE = 'GET_PROJECTS_DATA_MORE'
const GET_PROJECTS_DATA_MORE_SUCCESS = 'GET_PROJECTS_DATA_MORE_SUCCESS'
const GET_PROJECTS_DATA_SUCCESS = 'GET_PROJECTS_DATA_SUCCESS'
const GET_PROJECTS_DATA_FAILURE = 'GET_PROJECTS_DATA_FAILURE'
const GET_PROJECTS_DATA_RESET = 'GET_PROJECTS_DATA_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getProjectsDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECTS_DATA,
    url: `${process.env.apiUrl}v0/projects/getproject`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getProjectsDataResetAction = () => ({
  type: GET_PROJECTS_DATA_RESET,
})

export const getProjectsDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PROJECTS_DATA_MORE,
    url: `${process.env.apiUrl}v0/projects/getproject`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getProjectsDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PROJECTS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECTS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PROJECTS_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PROJECTS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const DELETE_PROJECTS_DATA = 'DELETE_PROJECTS_DATA'
const DELETE_PROJECTS_DATA_RESET = 'DELETE_PROJECTS_DATA_RESET'
const DELETE_PROJECTS_DATA_SUCCESS = 'DELETE_PROJECTS_DATA_SUCCESS'
const DELETE_PROJECTS_DATA_FAILURE = 'DELETE_PROJECTS_DATA_FAILURE'

export const deleteProjectsDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: DELETE_PROJECTS_DATA,
    url: `${process.env.apiUrl}v0/project/deleteproject?${stringify(payload.params)}`,
    method: 'DELETE',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true,
  message: 'Project deleted successfully',
  hideNotification: false,
})

export const deleteProjectsDataResetAction = () => ({
  type: DELETE_PROJECTS_DATA_RESET,
})

export function deleteProjectsDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case DELETE_PROJECTS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_PROJECTS_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_PROJECTS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case DELETE_PROJECTS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
