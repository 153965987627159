// import { stringify } from 'query-string';
import {
  map, catchError, mergeMap, takeUntil, filter, delay,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const ADD_NETWORK = 'ADD_NETWORK';
const ADD_NETWORK_CANCEL = 'ADD_NETWORK_CANCEL';
const ADD_NETWORK_RESET = 'ADD_NETWORK_RESET';
const ADD_NETWORK_CONFLICT = 'ADD_NETWORK_CONFLICT';
const ADD_NETWORK_SUCCESS = 'ADD_NETWORK_SUCCESS';
const ADD_NETWORK_FAILURE = 'ADD_NETWORK_FAILURE';

export const addNetworkCancelAction = () => ({
  type: ADD_NETWORK_CANCEL,
});

// Auto Complete action
export const addNetworkAction = (payload) => ({
  type: ADD_NETWORK,
  payload,
});

// Auto Complete Success action
const addNetworkSuccessAction = (payload) => ({
  type: ADD_NETWORK_SUCCESS,
  payload,
  showNotification: true,
  message: 'Network added successfully',
});

// Add project reset action
export const addNetworkResetAction = () => ({
  type: ADD_NETWORK_RESET,
});

export const addNetworkEpic = (actions$) => actions$.pipe(
  ofType(ADD_NETWORK),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}v0/search/createNetwork`, 'POST', action.payload.body, true),
  ).pipe(
    map((res) => addNetworkSuccessAction(res)),
    takeUntil(actions$.pipe(ofType(ADD_NETWORK_CANCEL))),
    catchError((err) => of({
      type: ADD_NETWORK_CONFLICT,
      payload: err,
    })),
  )),
);

export function addNetworkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_NETWORK_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_NETWORK_RESET: {
      return {
        data: {},
        // data: action.payload.response.data,
        loading: false,
        flag: false,
        error: false,
      };
    }
    case ADD_NETWORK_FAILURE: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: true,
      };
    }
    case ADD_NETWORK_CONFLICT: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const ADD_PI_TO_NETWORK = 'ADD_PI_TO_NETWORK';
const ADD_PI_TO_NETWORK_CANCEL = 'ADD_PI_TO_NETWORK_CANCEL';
const ADD_PI_TO_NETWORK_RESET = 'ADD_PI_TO_NETWORK_RESET';
const ADD_PI_TO_NETWORK_SUCCESS = 'ADD_PI_TO_NETWORK_SUCCESS';
const ADD_PI_TO_NETWORK_FAILURE = 'ADD_PI_TO_NETWORK_FAILURE';

export const addPiToNetworkCancelAction = () => ({
  type: ADD_PI_TO_NETWORK_CANCEL,
});

// Auto Complete action
export const addPiToNetworkAction = (payload) => ({
  type: ADD_PI_TO_NETWORK,
  payload,
});

// Auto Complete Success action
const addPiToNetworkSuccessAction = (payload) => ({
  type: ADD_PI_TO_NETWORK_SUCCESS,
  payload,
  showNotification: true,
  message: payload.response.data.message.length ? payload.response.data.message : 'Network updated successfully',
});

// Add project reset action
export const addPiToNetworkResetAction = () => ({
  type: ADD_PI_TO_NETWORK_RESET,
});

export const addPiToNetworkEpic = (actions$) => actions$.pipe(
  ofType(ADD_PI_TO_NETWORK),
  mergeMap((action) => ajax(
    apiCall(`${process.env.apiUrl}/v0/search/modifyPItoNetworks?pi_id=${action.payload.pi_id}&type=${action.payload.type}`, 'PUT', action.payload.body, true),
  ).pipe(
    map((res) => addPiToNetworkSuccessAction(res)),
    takeUntil(actions$.pipe(ofType(ADD_PI_TO_NETWORK_CANCEL))),
    catchError((err) => of({
      type: ADD_PI_TO_NETWORK_FAILURE,
      payload: err,
    })),
  )),
);

export function addPiToNetworkReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PI_TO_NETWORK: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case ADD_PI_TO_NETWORK_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case ADD_PI_TO_NETWORK_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      };
    }
    case ADD_PI_TO_NETWORK_FAILURE: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}

const NETWORK_AUTOSUGGEST = 'NETWORK_AUTOSUGGEST'
const GET_MORE_NETWORK_AUTOSUGGEST = 'GET_MORE_NETWORK_AUTOSUGGEST'
const NETWORK_AUTOSUGGEST_SUCCESS = 'NETWORK_AUTOSUGGEST_SUCCESS'
const GET_MORE_NETWORK_AUTOSUGGEST_SUCCESS = 'GET_MORE_NETWORK_AUTOSUGGEST_SUCCESS'
const GET_NETWORK_AUTOSUGGEST_FAILURE = 'GET_NETWORK_AUTOSUGGEST_FAILURE'
const GET_NETWORK_AUTOSUGGEST_RESET = 'GET_NETWORK_AUTOSUGGEST_RESET'

export const getNetworkAutoSuggestResetAction = () => {
  return ({
    type: GET_NETWORK_AUTOSUGGEST_RESET,
  })
}

export const getNetworkAutoSuggestAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: NETWORK_AUTOSUGGEST,
    url: `${process.env.apiUrl}v0/search/list`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getMoreNetworkAutoSuggestAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_MORE_NETWORK_AUTOSUGGEST,
    url: `${process.env.apiUrl}v0/search/list`,
    method: 'get',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const genericEpic = (actions$) => actions$.pipe(
  ofType(GENERIC_ACTION),
  mergeMap((action) => of({
    type: `${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
  }, {
    type: action.meta.actionType,
    payload: action.payload,
    meta: action.meta,
    origin: 'generic',
    message: action.message,
    showNotification: action.showNotification,
    hideNotification: action.hideNotification,
    dummyResponse: action.dummyResponse,
  })),
);

export const apiCallEpic = (actions$) => actions$.pipe(
  filter((action) => action.origin === 'generic' && action.type.indexOf('_CANCEL') === -1),
  mergeMap((action) => {
    if (action.meta.log) {
      console.log(action)
    }
    if (action.dummyResponse) {
      return of({
        type: `${action.meta.actionType}_SUCCESS`,
        payload: action.dummyResponse,
        meta: action.meta,
      }).pipe(
        delay(700),
      )
    }
    return ajax(apiCall(action.meta.url, action.meta.method, action.payload, action.meta.auth, action.meta.customHeaders))
      .pipe(
        map((res) => ({
          type: `${action.meta.actionType}_SUCCESS`,
          payload: res.response,
          meta: action.meta,
          showNotification: action.showNotification,
          message: action.message,
        })),
        takeUntil(actions$.pipe(ofType(`${action.meta.actionType}_CANCEL${action.meta.key ? `_${action.meta.key}` : ''}`))),
        catchError((err) => of({
          type: `${action.meta.actionType}_FAILURE`,
          payload: err,
          meta: action.meta,
          hideNotification: action.hideNotification,
        })),
      )
  }),
);

export function getNetworkAutoSuggestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NETWORK_AUTOSUGGEST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case NETWORK_AUTOSUGGEST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MORE_NETWORK_AUTOSUGGEST_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_NETWORK_AUTOSUGGEST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_NETWORK_AUTOSUGGEST_RESET: {
      console.log('reset called')

      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
