import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_MEDIDATA_FOR_COUNTRY = 'GET_MEDIDATA_FOR_COUNTRY';
const GET_MEDIDATA_FOR_COUNTRY_SUCCESS = 'GET_MEDIDATA_FOR_COUNTRY_SUCCESS';
const GET_MEDIDATA_FOR_COUNTRY_MORE = 'GET_MEDIDATA_FOR_COUNTRY_MORE';
const GET_MEDIDATA_FOR_COUNTRY_FAILURE = 'GET_MEDIDATA_FOR_COUNTRY_FAILURE';
const GET_MEDIDATA_FOR_COUNTRY_MORE_SUCCESS = 'GET_MEDIDATA_FOR_COUNTRY_MORE_SUCCESS';

export const getMedidataForCountryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MEDIDATA_FOR_COUNTRY,
      url: `${process.env.apiUrl}v0/country_profile/getMedidataForCountry`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getMedidataForCountryMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MEDIDATA_FOR_COUNTRY_MORE,
      url: `${process.env.apiUrl}v0/country_profile/getMedidataForCountry`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}
export function getMedidataForCountryReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_MEDIDATA_FOR_COUNTRY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_MEDIDATA_FOR_COUNTRY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_MEDIDATA_FOR_COUNTRY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    case GET_MEDIDATA_FOR_COUNTRY_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state;
  }
}

const GET_MEDIDATA_FILTERS_FOR_COUNTRY = 'GET_MEDIDATA_FILTERS_FOR_COUNTRY';
const GET_MEDIDATA_FILTERS_FOR_COUNTRY_SUCCESS = 'GET_MEDIDATA_FILTERS_FOR_COUNTRY_SUCCESS';
const GET_MEDIDATA_FILTERS_FOR_COUNTRY_FAILURE = 'GET_MEDIDATA_FILTERS_FOR_COUNTRY_FAILURE';

export const getMedidataFiltersForCountryAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MEDIDATA_FILTERS_FOR_COUNTRY,
      url: `${process.env.apiUrl}v0/country_profile/getMedidataFilters`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export function getMedidataFilterForCountryReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_MEDIDATA_FILTERS_FOR_COUNTRY: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_MEDIDATA_FILTERS_FOR_COUNTRY_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_MEDIDATA_FILTERS_FOR_COUNTRY_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    default:
      return state;
  }
}
