const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GENERIC_ACTION = 'GENERIC_ACTION'

const GET_MEDIDATA_SITE_OR_PI = 'GET_MEDIDATA_SITE_OR_PI';

const GET_MEDIDATA_SITE_OR_PI_SUCCESS = 'GET_MEDIDATA_SITE_OR_PI_SUCCESS';
const GET_MEDIDATA_SITE_OR_PI_FAILURE = 'GET_MEDIDATA_SITE_OR_PI_FAILURE';

const GET_MEDIDATA_SITE_OR_PI_MORE = 'GET_MEDIDATA_SITE_OR_PI_MORE';
const GET_MEDIDATA_SITE_OR_PI_MORE_SUCCESS = 'GET_MEDIDATA_SITE_OR_PI_MORE_SUCCESS'

// export const getMedidataSiteOrPICancelAction = () => ({
//   type: GET_MEDIDATA_SITE_OR_PI_CANCEL,
// });

// export const getMedidataSiteOrPIResetAction = () => ({
//   type: GET_MEDIDATA_SITE_OR_PI_RESET,
// });

// export const getMedidataSiteOrPIAction = (payload) => ({
//   type: GET_MEDIDATA_SITE_OR_PI,
//   payload,
// });

// const getMedidataSiteOrPISuccess = (payload) => ({
//   type: GET_MEDIDATA_SITE_OR_PI_SUCCESS,
//   payload,
// });

// export const getMedidataSiteOrPIEpic = (actions$) => actions$.pipe(
//   ofType(GET_MEDIDATA_SITE_OR_PI),
//   debounceTime(500),
//   mergeMap((action) => ajax(
//     apiCall(`${process.env.apiUrl}/v0/search/getMedidataForSiteOrPI?${stringify(action.payload)}`, 'get'),
//   ).pipe(
//     map((res) => getMedidataSiteOrPISuccess(res)),
//     takeUntil(actions$.pipe(ofType(GET_MEDIDATA_SITE_OR_PI_CANCEL))),
//     catchError((err) => of({
//       type: GET_MEDIDATA_SITE_OR_PI_FAILURE,
//       payload: err,
//     })),
//   )),
// );

// export function getMedidataSiteOrPIReducer(state = INITIAL_STATE, action) {
//   switch (action.type) {
//     case GET_MEDIDATA_SITE_OR_PI: {
//       return {
//         data: {},
//         loading: true,
//         flag: false,
//         error: false,
//       };
//     }
//     case GET_MEDIDATA_SITE_OR_PI_SUCCESS: {
//       return {
//         data: action.payload.response.data,
//         loading: false,
//         flag: true,
//         error: false,
//       };
//     }
//     case GET_MEDIDATA_SITE_OR_PI_FAILURE: {
//       return {
//         data: {},
//         loading: false,
//         flag: false,
//         error: true,
//       };
//     }
//     case GET_MEDIDATA_SITE_OR_PI_RESET: {
//       return {
//         data: {},
//         loading: false,
//         flag: false,
//         error: false,
//       }
//     }
//     default:
//       return state;
//   }
// }

export const getMedidataSiteOrPIAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MEDIDATA_SITE_OR_PI,
      url: `${process.env.apiUrl}v0/search/getMedidataForSiteOrPI`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getMedidataSiteOrPIMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MEDIDATA_SITE_OR_PI_MORE,
      url: `${process.env.apiUrl}v0/search/getMedidataForSiteOrPI`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}
export function getMedidataSiteOrPIReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_MEDIDATA_SITE_OR_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_MEDIDATA_SITE_OR_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_MEDIDATA_SITE_OR_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }

    case GET_MEDIDATA_SITE_OR_PI_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state;
  }
}
