import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
}

const MERGE_PRE_REQUEST = 'MERGE_PRE_REQUEST'
const MERGE_PRE_REQUEST_SUCCESS = 'MERGE_PRE_REQUEST_SUCCESS'
const MERGE_PRE_REQUEST_FAILURE = 'MERGE_PRE_REQUEST_FAILURE'

export const mergePreRequestAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: MERGE_PRE_REQUEST,
      url: `${process.env.apiUrl}v0/search/mergePIorSitePreRequest?type=${payload.type}`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function mergePreRequestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MERGE_PRE_REQUEST: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MERGE_PRE_REQUEST_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MERGE_PRE_REQUEST_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const MERGE_REQUEST = 'MERGE_REQUEST'
const MERGE_REQUEST_SUCCESS = 'MERGE_REQUEST_SUCCESS'
const MERGE_REQUEST_RESET = 'MERGE_REQUEST_RESET'
const MERGE_REQUEST_FAILURE = 'MERGE_REQUEST_FAILURE'

export const mergeRequestAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: MERGE_REQUEST,
      url: `${process.env.apiUrl}v0/search/mergePIorSiteRequest`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Merge request sent for approval',
    // hideNotification: false, // hide notification on error
  })
}

export const mergeRequestResetAction = () => ({
  type: MERGE_REQUEST_RESET,
})

export function mergeRequestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MERGE_REQUEST: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case MERGE_REQUEST_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case MERGE_REQUEST_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    case MERGE_REQUEST_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_CARD_DATA = 'GET_CARD_DATA'
const GET_CARD_DATA_SUCCESS = 'GET_CARD_DATA_SUCCESS'
const GET_CARD_DATA_FAILURE = 'GET_CARD_DATA_FAILURE'

export const getCardDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_CARD_DATA,
    url: `${process.env.apiUrl}/v0/search/overviewMergeRequestSitePI`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getCardDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CARD_DATA: {
      // console.log('action.payload', state.data)
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_CARD_DATA_SUCCESS: {
      // console.log('action.payload', state.data)
      if (action.meta.type) {
        return {
          data: {
            ...state.data, [action.meta.type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CARD_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_MERGE_DOC = 'GET_MERGE_DOC'
const GET_MERGE_DOC_SUCCESS = 'GET_MERGE_DOC_SUCCESS'
const GET_MERGE_DOC_FAILURE = 'GET_MERGE_DOC_FAILURE'

export const getMergeDocAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_MERGE_DOC,
      url: `${process.env.apiUrl}v0/search/getMergedSitePiDoc`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getMergeDocReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_MERGE_DOC: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_MERGE_DOC_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_MERGE_DOC_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const APPROVAL_MERGE = 'APPROVAL_MERGE'
const APPROVAL_MERGE_SUCCESS = 'APPROVAL_MERGE_SUCCESS'
const APPROVAL_MERGE_FAILURE = 'APPROVAL_MERGE_FAILURE'

export const approvalMergeAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: APPROVAL_MERGE,
      url: `${process.env.apiUrl}v0/search/adminApprovalMerge?type=${payload.type}&innoplexus_id=${payload.innoplexus_id}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Merge request status updated',
    hideNotification: false, // hide notification on error
  })
}

export function approvalMergeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APPROVAL_MERGE: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case APPROVAL_MERGE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case APPROVAL_MERGE_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const APPROVAL_DELETE = 'APPROVAL_DELETE'
const APPROVAL_DELETE_SUCCESS = 'APPROVAL_DELETE_SUCCESS'
const APPROVAL_DELETE_FAILURE = 'APPROVAL_DELETE_FAILURE'
const APPROVAL_DELETE_RESET = 'APPROVAL_DELETE_RESET'

export const approvalDeleteAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: APPROVAL_DELETE,
      url: `${process.env.apiUrl}/v0/search/adminApprovalDelete?type=${payload.type}&innoplexus_id=${payload.innoplexus_id}&id=${payload.id}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Profile details deleted successfully',
    hideNotification: false, // hide notification on error
  })
}

export const approvalDeleteResetAction = () => ({
  type: APPROVAL_DELETE_RESET,
})

export function approvalDeleteReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case APPROVAL_DELETE: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case APPROVAL_DELETE_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case APPROVAL_DELETE_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case APPROVAL_DELETE_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
