import {
  Button, Tooltip, Input, Modal, Checkbox,
} from 'antd';
import { parse, stringify } from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import Axios from 'axios';
import Loader from '../../../common/components/Loader';
import { notify, setAnalytics, getCookie } from '../../../utils';

import {
  deletePiFromNetworkAction,
  getNetworkDetailsAction,
  getPiAutoSuggestAction,
  getPiAutoSuggestResetAction,
  putPiToNetworkAction,
  getPiAutoSuggestMoreAction,
  getNetworkDetailsMoreAction,
  deletePiFromNetworkResetAction,
  putPiToNetworkResetAction,
} from './logic';
import BarChart from '../../../common/components/BarChart';
import ReadMore from '../../../common/components/ReadMore';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';

import InputWithCopy from '../CopyToClipboard';

const NetworkPIList = ({ appliedFilters, networkType, piList, innoplexusId }) => {
  const networkData = useSelector((store) => store.getNetworkDetails);
  const deletePiFromNetwork = useSelector((store) => store.deletePiFromNetwork);
  const piAutoSuggest = useSelector((store) => store.getPiAutoSuggest);
  const putPiToNetwork = useSelector((store) => store.putPiToNetwork);

  const [networkMetaDetails, setNetworkMetaDetails] = useState({});
  // const [localLoading, setLocalLoading] = useState(true);
  // const [piList, setPiList] = useState();
  const [piValueToSearch, setPiValueToSearch] = useState('');
  const [addPiToNetworkModal, setAddNewPiToNetworkModal] = useState(false);
  const [fromForPi, setFromForPi] = useState(1);
  const [fromForPiInNetworkList, setFromForPiInNetworkList] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);

  const [piListToSend, setPiListToSend] = useState([]);
  const [piNameToSearch, setPiNameToSearch] = useState('');
  const [piIdToDelete, setPiIdToDelete] = useState('');

  const dispatch = useDispatch();
  // const modifyNetworkRespose = useSelector((store) => store.modifyNetwork);
  const { Search } = Input;

  const type = 'investigators';

  // useEffect(() => {
  //   const network_id = parse(location.search).id;
  //   const from = 1;
  //   const size = 10;
  //   const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });

  //   dispatch(
  //     getNetworkDetailsAction({
  //       type,
  //       network_id,
  //       from,
  //       size,
  //       filters,
  //     }),
  //   );
  //   setFromForPiInNetworkList(fromForPiInNetworkList + 1);
  // }, []);

  useEffect(() => {
    setFromForPiInNetworkList(1)
  }, [appliedFilters])

  // useEffect(() => {
  //   if (modifyNetworkRespose.flag) {
  //     const network_id = parse(location.search).id;
  //     const from = 1;
  //     const size = 10;
  //     const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });

  //     dispatch(
  //       getNetworkDetailsAction({
  //         type,
  //         network_id,
  //         from,
  //         size,
  //         filters,
  //       }),
  //     );
  //   }
  // }, [JSON.stringify(modifyNetworkRespose)]);

  useEffect(() => {
    if (putPiToNetwork.flag) {
      const network_id = parse(location.search).id;
      const from = 1;
      const size = 10;
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });
      setFromForPiInNetworkList(1)

      dispatch(
        getNetworkDetailsAction({
          type,
          network_id,
          from,
          size,
          filters,
        }),
      );
      dispatch(putPiToNetworkResetAction({}))
    }
  }, [JSON.stringify(putPiToNetwork)]);

  // useEffect(() => {
  //   if (networkData.flag) {
  //     setNetworkMetaDetails(networkData.data.data.network_details);
  //     setPiList(networkData.data.data.pi_summary);
  //     // setLocalLoading(false);
  //   }
  // }, [JSON.stringify(networkData)]);

  useEffect(() => {
    if (deletePiFromNetwork.flag) {
      const network_id = parse(location.search).id;
      const from = 1;
      const size = 10;
      const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });
      setFromForPiInNetworkList(1)

      dispatch(
        getNetworkDetailsAction({
          type,
          network_id,
          from,
          size,
          filters,
        }),
      );
      dispatch(deletePiFromNetworkResetAction({}))
    }
  }, [JSON.stringify(deletePiFromNetwork)]);

  const showPieChart = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  const onInvestigatorChangeInput = (e) => {
    if (e.trim() && e.length > 2) {
      setPiNameToSearch(e);
      dispatch(
        getPiAutoSuggestAction({
          query: e.trim(),
          from: 1,
          dataset: 'investigators',
          size: 10,
        }),
      );
      setFromForPi(1);
    }
  };

  const loadMoreRowsPiList = () => {
    dispatch(
      getPiAutoSuggestMoreAction({
        query: piNameToSearch,
        from: fromForPi + 1,
        dataset: 'investigators',
        size: 10,
      }),
    );
    setFromForPi(fromForPi + 1);
  };

  const loadMoreRowsPiListInNetwork = () => {
    const network_id = parse(location.search).id;
    const from = fromForPiInNetworkList + 1;
    const size = 10;
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });
    const query = piValueToSearch;

    setFromForPiInNetworkList(fromForPiInNetworkList + 1);

    dispatch(
      getNetworkDetailsMoreAction({
        network_id,
        from,
        size,
        type: 'investigators',
        filters,
        query,
      }),
    );
  };

  const handleInvestigatorCheck = (id) => {
    if (piListToSend.includes(id)) {
      const listToSend = piListToSend.filter((ele) => ele !== id);
      setPiListToSend(listToSend);
    } else {
      setPiListToSend((current) => [...current, id]);
    }
  };

  const renderAutoSuggestOptions = () => {
    if (piAutoSuggest.data.data.length === 0) {
      return <div className="no-data-found-msg">No PI found</div>;
    }
    return piAutoSuggest.data.data.map((itm) => {
      return (
        <div className={`card ${itm.type}`} role="presentation">
          <div className="card-header card-header-notitle card-header-investigator">
            <div className="card-meta">
              <div className="card-meta-header" />
              <div className="card-meta-footer">
                <div className="source" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="card-person-img">
              <div className="img-person no-img" />
            </div>
            <div className="leftside">
              <div className="title">{itm.name}</div>
              <div className="info-ratings-sec info-ratings-sec-left">
                <div className="info-ratings-values">
                  <div className="info-ratings-values-title">Industry studies:</div>
                  <div className="info-ratings-values-data">{itm.industry_studies}</div>
                </div>
                <div className="info-ratings-values">
                  <div className="info-ratings-values-title">Parexel studies:</div>
                  <div className="info-ratings-values-data">{itm.parexel_studies}</div>
                </div>
                <div className="info-ratings-values">
                  <div className="info-ratings-values-title">Tier:</div>
                  <div className="info-ratings-values-data">{itm.tier}</div>
                </div>
              </div>
              <div className="info-ratings-sec info-ratings-sec-right">
                <div className="info-ratings-values">
                  <div className="info-ratings-values-title">Enrolled subjects:</div>
                  {itm?.enrollment_subjects ? (
                    <div className="info-ratings-values-data">{itm.enrollment_subjects}</div>
                  ) : (
                    <div className="info-ratings-values-data">0</div>
                  )}
                </div>
                <div className="info-ratings-values">
                  <div className="info-ratings-values-title">Recruitment:</div>
                  <div className="reliability-quality-rating">
                    <div className="reliability rating-sec">
                      {itm.recruitment !== '' ? (
                        <StarRatings
                          rating={parseInt(itm.recruitment, 10)}
                          starRatedColor="#8A0050"
                          numberOfStars={3}
                          starDimension="16px"
                          starSpacing="1px"
                          starEmptyColor="#D099B9"
                        />
                      ) : (
                        <div className="dash-nodata">-</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rightside">
              <div className="contact-details">
                <div className="email">
                  <span className="title">Email:</span>
                  {itm.email && itm.email.length ? (
                    <Tooltip placement="bottom" title={itm.email.join(', ')}>
                      <span className="value">{itm.email.join(', ')}</span>
                    </Tooltip>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>
                      No data available
                    </span>
                  )}
                </div>
                <div className="phone">
                  <span className="title">Phone:</span>
                  {itm.phone_number ? (
                    <Tooltip placement="bottom" title={itm.phone_number.join(', ')}>
                      <span className="value">{itm.phone_number.join(', ')}</span>
                    </Tooltip>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>
                      No data available
                    </span>
                  )}
                </div>
              </div>
              <div className="chart-sec">
                <div className="chart">
                  {showPieChart(itm?.phase_pie_chart) ? (
                    <BarChart
                      data={itm?.phase_pie_chart}
                      width={160}
                      height={120}
                      top={20}
                      bottom={20}
                      left={40}
                      right={20}
                    />
                  ) : (
                    <span>No data available</span>
                  )}
                </div>
                <div className="chart-data">
                  <div className="chart-content">
                    <span className="chart-title">Ongoing Studies:</span>
                    <span className="chart-value">{itm.ongoing_studies}</span>
                  </div>
                  <div className="chart-content">
                    <div className="primary-org">
                      <span className="title chart-title">Parent Institution Name:&nbsp;</span>
                      {Object.keys(itm.parent_institution) && Object.keys(itm.parent_institution).length ? (
                        itm.parent_institution.name
                      ) : (
                        <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                      )}
                    </div>
                  </div>
                  <div className="chart-content">
                    <span className="chart-title">Primary Center:</span>
                    <span className="chart-value" title={itm.affiliated_site}>
                      <ReadMore type="text" limit={100}>
                        {itm.affiliated_site || '-'}
                      </ReadMore>
                    </span>
                  </div>
                  <div className="chart-content">
                    <span className="chart-title"># Indications:</span>
                    <span className="chart-value">
                      {itm.indications && itm.indications.length ? itm.indications.length : 0}
                    </span>
                  </div>

                  <div className="chart-content">
                    <span className="chart-title">Indications:</span>
                    <Tooltip
                      placement="bottom"
                      title={
                        itm.indications && itm.indications.length ? itm.indications.join('; ') : ''
                      }
                    >
                      <span className="chart-value">
                        <ReadMore type="text" limit={100}>
                          {itm.indications && itm.indications.length
                            ? itm.indications.join('; ')
                            : ''}
                        </ReadMore>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <Checkbox onClick={() => handleInvestigatorCheck(itm.id)} />
          </div>
        </div>
      );
    });
  };
  const searchPiValue = () => {
    const from = 1;
    const size = 10;
    const network_id = parse(location.search).id;
    const query = piValueToSearch;
    const filters = JSON.stringify({ ...appliedFilters, parent_flag: false });
    setFromForPiInNetworkList(1)

    dispatch(
      getNetworkDetailsAction({
        type,
        network_id,
        query,
        from,
        size,
        filters,
      }),
    );
  };

  const handleAddPiToNetwork = () => {
    dispatch(
      putPiToNetworkAction({
        innoplexus_id: innoplexusId,
        type,
        pi_id: piListToSend,
      }),
    );
    setPiListToSend([]);
    dispatch(getPiAutoSuggestResetAction({}));
    setAddNewPiToNetworkModal(false);
    const params = {
      innoplexus_id: innoplexusId,
      type,
      pi_id: piListToSend,
    };
    setAnalytics('networkDeepDiveAddPi', 'data', params);
  };
  const handleDeletePi = () => {
    const pi_id = piIdToDelete.id;

    dispatch(
      deletePiFromNetworkAction({
        pi_id,
        innoplexus_id: innoplexusId,
        type,
      }),
    );
    setPiIdToDelete(false);
    setDeleteModal(false);
  };

  const handleDeleteClick = (id) => {
    setPiIdToDelete(id);
    setDeleteModal(true);
  };
  const renderPiList = () => {
    if (piList?.length === 0) {
      return <div className="no-data-found-msg">No investigators in this network</div>;
    }
    return piList?.map((itm) => {
      return (
        <div className={`card ${itm.type}`} role="presentation">
          <div className="card-header card-header-notitle card-header-investigator">
            <div className="card-meta">
              <div className="card-meta-header" />
              <div className="card-meta-footer">
                <div className="source" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="card-person-img">
              <div className="img-person no-img" />
            </div>
            <div className="leftside">
              <div className="title">
                <a
                  className="pi-wrap"
                  title={itm.name}
                  target="_blank"
                  href={`/search/investigatorprofile?${stringify({
                    query: itm.name,
                    id: itm.id,
                    type: 'PI',
                  })}`}
                  rel="noreferrer noopener"
                >
                  {itm.name}
                </a>
              </div>
              <div className="info-ratings-sec-parent" style={{ justifyContent: 'flex-start' }}>
                <div className="info-ratings-sec info-ratings-sec-left">
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Industry studies:</div>
                    <div className="info-ratings-values-data">{itm.industry_studies}</div>
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Parexel studies:</div>
                    <div className="info-ratings-values-data">{itm.parexel_studies}</div>
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Alliance type:</div>
                    {itm.alliance_type ? (
                      <div className="normal-text">{itm.alliance_type}</div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Alliance member:</div>
                    <div
                      className="info-ratings-values-data"
                      title={itm.alliance_member ? itm.alliance_member : '-'}
                    >
                      {itm.alliance_member ? itm.alliance_member : '-'}
                    </div>
                  </div>

                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Tier:</div>
                    <div className="info-ratings-values-data">{itm.tier}</div>
                  </div>
                </div>

                <div className="info-ratings-sec info-ratings-sec-right">
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Enrolled subjects:</div>
                    {itm.enrollment_subjects && itm.enrollment_subjects !== '' ? (
                      <div className="info-ratings-values-data">{itm.enrollment_subjects}</div>
                    ) : (
                      <div className="info-ratings-values-data">0</div>
                    )}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Reliability:</div>
                    <div className="reliability-quality-rating">
                      <div className="reliability rating-sec">
                        {itm.reliability !== '' ? (
                          <StarRatings
                            rating={itm.reliability}
                            starRatedColor="#8A0050"
                            numberOfStars={3}
                            starDimension="16px"
                            starSpacing="1px"
                            starEmptyColor="#D099B9"
                          />
                        ) : (
                          <div className="dash-nodata">-</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Quality:</div>
                    <div className="reliability-quality-rating">
                      <div className="reliability rating-sec">
                        {itm.quality_rating !== '' ? (
                          <StarRatings
                            rating={itm.quality_rating}
                            starRatedColor="#8A0050"
                            numberOfStars={3}
                            starDimension="16px"
                            starSpacing="1px"
                            starEmptyColor="#D099B9"
                          />
                        ) : (
                          <div className="dash-nodata">-</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Last trial start date:</div>
                    {itm.last_trial_start_date ? (
                      <div className="info-ratings-values-data plain-text no-ellipsis">
                        {itm.last_trial_start_date}
                      </div>
                    ) : (
                      <div className="dash-nodata">-</div>
                    )}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">LandSCAPE ID:</div>
                    {itm.landscape_id ? (
                      <div className="Alliance member:-data plain-text no-ellipsis">
                        {itm.landscape_id}
                      </div>
                    ) : (
                      <div className="dash-nodata">-</div>
                    )}
                  </div>
                </div>
                <div className="info-ratings-sec info-ratings-sec-right">
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Compliance:&nbsp;</div>
                    <div className="normal-text">{itm.compliance}</div>
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Sources:&nbsp;</div>
                    {itm.sources && itm.sources.length ? (
                      <div className="normal-text">{itm.sources.join('; ')}</div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">NPI:&nbsp;</div>
                    {itm.npi ? <div>{itm.npi}</div> : <div>-</div>}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">SIMS IDs:&nbsp;</div>
                    {itm.pxl_author_sims_ids && itm.pxl_author_sims_ids.length ? (
                      <div className="info-ratings-values-data">
                        {itm.pxl_author_sims_ids.join(', ')}
                      </div>
                    ) : (
                      <div className="info-ratings-values-data">-</div>
                    )}
                  </div>
                  <div className="info-ratings-values">
                    <div className="info-ratings-values-title">Citeline IDs:&nbsp;</div>
                    {itm.pxl_author_cit_ids && itm.pxl_author_cit_ids.length ? (
                      <div className="info-ratings-values-data">
                        {itm.pxl_author_cit_ids.join(', ')}
                      </div>
                    ) : (
                      <div className="info-ratings-values-data">-</div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="rightside">
              <div className="contact-details">
                <div className="email">
                  <span className="title">Email:</span>
                  {itm.email && itm.email.length ? (
                    <>
                      <Tooltip
                        className="copy-w-email"
                        placement="bottom"
                        title={itm.email.join(', ')}
                      >
                        <span className="value">{itm.email.join(', ')}</span>
                        <InputWithCopy text={itm} type="email" />
                      </Tooltip>
                    </>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>
                      No data available
                    </span>
                  )}
                  <span className="title">Survey Email:</span>
                  {itm.survey_email && itm.survey_email.length ? (
                    <Tooltip
                      placement="bottom"
                      className="copy-w-email"
                      title={itm.survey_email.join(', ')}
                    >
                      <span className="value">{itm.survey_email.join(', ')}</span>
                      <InputWithCopy text={itm} type="survey_email" />
                    </Tooltip>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>
                      No data available
                    </span>
                  )}
                </div>
                <div className="phone">
                  <span className="title">Phone:</span>
                  {itm.phone_number ? (
                    <Tooltip placement="bottom" title={itm.phone_number.join(', ')}>
                      <span className="value">{itm.phone_number.join(', ')}</span>
                    </Tooltip>
                  ) : (
                    <span className="value" style={{ fontSize: '14px' }}>
                      No data available
                    </span>
                  )}
                </div>
              </div>
              <div className="chart-sec">
                <div className="chart">
                  {showPieChart(itm?.indication_pie_chart) ? (
                    <BarChart
                      data={itm?.indication_pie_chart.slice(0, 10)}
                      width={180}
                      height={120}
                      top={20}
                      bottom={5}
                      left={40}
                      right={20}
                      studies="indication"
                      checkDataset="investigators"
                      hideLabels
                    />
                  ) : (
                    <span className="no-data-chart">No data available</span>
                  )}
                </div>
                <div className="chart-data">
                  <div className="chart-content">
                    <span className="chart-title">Ongoing Studies:</span>
                    <span className="chart-value">{itm.ongoing_studies}</span>
                  </div>
                  <div className="chart-content">
                    <div className="primary-org">
                      <span className="title chart-title">Parent Institution Name:&nbsp;</span>
                      {Object.keys(itm.parent_institution) && Object.keys(itm.parent_institution).length ? (
                        <a
                          target="_blank"
                          href={`/search/deepdives?${stringify({
                            ...parse(location.search),
                            id: itm.parent_institution.id,
                            currentTab: 'parent_centers',
                          })}`}
                          rel="noopener noreferrer"
                        >{itm.parent_institution.name}
                        </a>
                      ) : (
                        <span className="value" style={{ fontSize: '14px' }}>No data available</span>
                      )}
                    </div>
                  </div>
                  <div className="chart-content">
                    <div className="primary-org">
                      <span className="title chart-title">Primary Center:&nbsp;</span>
                      {itm.affiliated_site ? (
                        <a
                          target="_blank"
                          href={`/search/deepdives?${stringify({
                            ...parse(location.search),
                            currentTab: 'centers',
                            id: itm.affiliated_site_id,
                            type: 'SITE',
                            query: itm.affiliated_site,
                          })}`}
                          rel="noopener noreferrer"
                          className="chart-value"
                        >
                          {itm.affiliated_site}
                        </a>
                      ) : (
                        <span className="value" style={{ fontSize: '14px' }}>
                          No data available
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="chart-content">
                    <span className="chart-title">Primary Center Address:</span>
                    {itm.primary_organization && itm.primary_organization.city ? (
                      <div className="chart-value">
                        {itm?.primary_organization?.address_line1
                          && `${itm.primary_organization.address_line1}, `}
                        {itm.primary_organization.address_line2
                          && `${itm.primary_organization.address_line2}, `}
                        {itm.primary_organization.city && `${itm.primary_organization.city}, `}
                        {itm.primary_organization.state && `${itm.primary_organization.state}, `}
                        {itm.primary_organization.country
                          && `${itm.primary_organization.country}, `}
                        {itm.primary_organization.zip}
                      </div>
                    ) : (
                      <div className="chart-value no-data">No data available</div>
                    )}
                  </div>

                  <div className="chart-content">
                    <span className="chart-title">Specialties:</span>
                    <Tooltip
                      placement="bottom"
                      title={
                        itm.specialties && itm.specialties.length ? itm.specialties.join('; ') : ''
                      }
                    >
                      <span className="chart-value">
                        <ReadMore type="text" limit={100}>
                          {itm.specialties && itm.specialties.length
                            ? itm.specialties.join('; ')
                            : ''}
                        </ReadMore>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <Button
              className="del-icon-btn"
              type="primary"
              onClick={() => handleDeleteClick(itm)}
            />
          </div>
        </div>
      );
    });
  };

  const handleAddNewPiToNetworkModalClose = () => {
    dispatch(getPiAutoSuggestResetAction());
    setPiListToSend([]);
    setAddNewPiToNetworkModal(false);
  };
  const handleAddPiToNetworkClick = () => {
    if (networkType === 'Center Network') {
      notify("You are not allowed to add the investigators for 'center network type'", 'error')
    } else {
      setPiListToSend([]);
      setAddNewPiToNetworkModal(true);
    }
  };

  const exportNetworksClicked = () => {
    const filename = 'investigators-list.xlsx'
    const network_id = parse(location.search).id;

    const params = {
      network_id,
      is_downloading: true,
      type: 'investigators',
      filters: JSON.stringify({ ...appliedFilters, parent_flag: false }),
    }
    Axios({
      url: `${process.env.apiUrl}v0/search/network/deepdive?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }
  return (
    <>
      {deleteModal && (
        <Modal
          title="Delete PI from Network"
          visible={deleteModal}
          onOk={handleDeletePi}
          onCancel={() => setDeleteModal(false)}
        >
          Do you really want to delete PI from this Network ?
        </Modal>
      )}
      <div className="whole-deep-dive-page whole-deep-dive-page-inpage">
        <div className="middle-part" style={{ minWidth: '100%' }}>
          <div className="upper-divof-search-Tabs">
            <div className="outer_large_div">
              <div className="network-inner-content-card">
                <Loader loading={networkData.loading} error={networkData.error}>
                  <>
                    <div className="header-network-content">
                      <div className="export-to-csv-btn network-deepdive">
                        <Button
                          className="export-to-csv-btn-icon export-networks-button"
                          title="Export"
                          onClick={() => exportNetworksClicked()}
                        />
                      </div>
                      <div className="header-network-content-search">
                        <Search
                          placeholder="Search PI"
                          allowClear
                          value={piValueToSearch}
                          onChange={(e) => setPiValueToSearch(e.target.value)}
                          onSearch={searchPiValue}
                          onPressEnter={searchPiValue}
                          enterButton
                        />
                      </div>

                      {!networkData.loading && networkData.flag && (
                        <>
                          {piList?.length ? (
                            <div className="show-doc-outof show-doc-outof-w-relative" style={{ marginBottom: '10px' }}>
                              <div className="show-doc-data">
                                Showing<span className="show-count">{piList?.length}</span> out of
                                <span className="show-count-number">{networkData?.data?.data?.totalPIs}</span> Investigators
                              </div>
                            </div>
) : undefined}

                          <LazyLoadDiv
                            className="card-list scrollbar"
                            id="pi-list-in-Network"
                            total={networkData.data.data.totalPIs}
                            currentTotal={(piList || []).length}
                            loadMoreRows={() => loadMoreRowsPiListInNetwork()}
                            height="calc(100vh - 435px)"
                          >
                            {renderPiList()}

                          </LazyLoadDiv>
                        </>

                      )}
                    </div>
                  </>
                </Loader>
              </div>
              <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis">
                <div className="plus-add-btn">
                  <button
                    onClick={() => handleAddPiToNetworkClick()}
                    type="button"
                    className="plus-btn"
                    // disabled={centerCount}
                  >
                    <span className="title" style={{ display: 'none' }}>
                      Add PI to Network&nbsp;
                    </span>
                    <span className="title-plus"> +</span>
                  </button>
                </div>
              </div>
              {addPiToNetworkModal && (
                <Modal
                  title="Add Investigators to Network"
                  visible={addPiToNetworkModal}
                  onOk={handleAddPiToNetwork}
                  onCancel={handleAddNewPiToNetworkModalClose}
                  width="1000"
                  className="add-investigator-network-modal"
                  okText="Add"
                  maskClosable={false}
                  okButtonProps={{ disabled: piListToSend.length === 0 }}
                >
                  <div className="add-investigator-to-network">
                    <Input
                      placeholder="Search PI here"
                      onChange={(e) => onInvestigatorChangeInput(e.target.value)}
                    />
                    <div className="Card">
                      <Loader loading={piAutoSuggest.loading} error={piAutoSuggest.error}>
                        {!piAutoSuggest.loading && piAutoSuggest.flag && (
                          <LazyLoadDiv
                            className="card-list scrollbar"
                            id="pi-list-in-search"
                            total={piAutoSuggest.data.total}
                            currentTotal={(piAutoSuggest.data.data || []).length}
                            loadMoreRows={() => loadMoreRowsPiList()}
                            height="calc(100vh - 275px)"
                          >
                            {renderAutoSuggestOptions()}
                          </LazyLoadDiv>
                        )}
                      </Loader>
                    </div>
                  </div>
                </Modal>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkPIList;
