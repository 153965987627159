import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../../SearchResults/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
}

const GET_SHORTLISTED_MAP_BG_DATA = 'GET_SHORTLISTED_MAP_BG_DATA'
const GET_SHORTLISTED_MAP_BG_DATA_SUCCESS = 'GET_SHORTLISTED_MAP_BG_DATA_SUCCESS'
const GET_SHORTLISTED_MAP_BG_DATA_FAILURE = 'GET_SHORTLISTED_MAP_BG_DATA_FAILURE'
const GET_SHORTLISTED_MAP_BG_DATA_RESET = 'GET_SHORTLISTED_MAP_BG_DATA_RESET'

export const getShortListedMapBgDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_SHORTLISTED_MAP_BG_DATA,
      url: `${process.env.apiUrl}v0/country/getBackgroundMapCount`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getShortListedMapBgDataResetAction = () => ({
  type: GET_SHORTLISTED_MAP_BG_DATA_RESET,
})

export function getShortListedBgMapDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHORTLISTED_MAP_BG_DATA: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SHORTLISTED_MAP_BG_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SHORTLISTED_MAP_BG_DATA_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SHORTLISTED_MAP_BG_DATA_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const GET_SHORTLISTED_MAP_MARKER_DATA = 'GET_SHORTLISTED_MAP_MARKER_DATA'
const GET_SHORTLISTED_MAP_MARKER_DATA_SUCCESS = 'GET_SHORTLISTED_MAP_MARKER_DATA_SUCCESS'
const GET_SHORTLISTED_MAP_MARKER_DATA_FAILURE = 'GET_SHORTLISTED_MAP_MARKER_DATA_FAILURE'
const GET_SHORTLISTED_MAP_MARKER_DATA_RESET = 'GET_SHORTLISTED_MAP_MARKER_DATA_RESET'

export const getShortListedMapMarkerDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_SHORTLISTED_MAP_MARKER_DATA,
      url: `${process.env.apiUrl}/v0/country/getShortlistedVsCandidates`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getShortListedMapMarkerDataResetAction = () => ({
  type: GET_SHORTLISTED_MAP_MARKER_DATA_RESET,
})

export function getShortListedMapMarkerDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SHORTLISTED_MAP_MARKER_DATA: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SHORTLISTED_MAP_MARKER_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SHORTLISTED_MAP_MARKER_DATA_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SHORTLISTED_MAP_MARKER_DATA_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const GET_PROJECT_PI_LIST = 'GET_PROJECT_PI_LIST'
const GET_PROJECT_PI_LIST_SUCCESS = 'GET_PROJECT_PI_LIST_SUCCESS'
const GET_PROJECT_PI_LIST_FAILURE = 'GET_PROJECT_PI_LIST_FAILURE'
const GET_PROJECT_PI_LIST_RESET = 'GET_PROJECT_PI_LIST_RESET'

export const getProjectPiListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PROJECT_PI_LIST,
      url: `${process.env.apiUrl}/v0/country/getProjectPisList`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getProjectPiListResetAction = () => ({
  type: GET_PROJECT_PI_LIST_RESET,
})

export function getProjectPiListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_PI_LIST: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_PI_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PROJECT_PI_LIST_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_PROJECT_PI_LIST_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const GET_PROJECT_PI_LIST_TO_RENDER = 'GET_PROJECT_PI_LIST_TO_RENDER'
const GET_PROJECT_PI_LIST_TO_RENDER_SUCCESS = 'GET_PROJECT_PI_LIST_TO_RENDER_SUCCESS'
const GET_PROJECT_PI_LIST_TO_RENDER_MORE = 'GET_PROJECT_PI_LIST_TO_RENDER_MORE'
const GET_PROJECT_PI_LIST_TO_RENDER_MORE_SUCCESS = 'GET_PROJECT_PI_LIST_TO_RENDER_MORE_SUCCESS'
const GET_PROJECT_PI_LIST_TO_RENDER_FAILURE = 'GET_PROJECT_PI_LIST_TO_RENDER_FAILURE'
const GET_PROJECT_PI_LIST_TO_RENDER_RESET = 'GET_PROJECT_PI_LIST_TO_RENDER_RESET'

export const getProjectPiListToRenderAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PROJECT_PI_LIST_TO_RENDER,
      url: `${process.env.apiUrl}/v0/country/getProjectPisList`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const getProjectPiListToRenderMoreAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PROJECT_PI_LIST_TO_RENDER_MORE,
      url: `${process.env.apiUrl}/v0/country/getProjectPisList`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}
export const getProjectPiListToRenderResetAction = () => ({
  type: GET_PROJECT_PI_LIST_TO_RENDER_RESET,
})

export function getProjectPiListToRenderReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT_PI_LIST_TO_RENDER: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_PI_LIST_TO_RENDER_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PROJECT_PI_LIST_TO_RENDER_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_PROJECT_PI_LIST_TO_RENDER_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PROJECT_PI_LIST_TO_RENDER_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    default:
      return state
  }
}

const ADD_TO_SNIPPET_VIEW = 'ADD_TO_SNIPPET_VIEW'
const ADD_TO_SNIPPET_VIEW_SUCCESS = 'ADD_TO_SNIPPET_VIEW_SUCCESS'
const ADD_TO_SNIPPET_VIEW_FAILURE = 'ADD_TO_SNIPPET_VIEW_FAILURE'
const ADD_TO_SNIPPET_VIEW_RESET = 'ADD_TO_SNIPPET_VIEW_RESET'

export const addToSnippetViewAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: ADD_TO_SNIPPET_VIEW,
      url: `${process.env.apiUrl}/v0/projects/projectMapSnippetView?${stringify(payload.params)}`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'PI added to snippet view',
    // hideNotification: false, // hide notification on error
  })
}

export const addToSnippetViewResetAction = () => ({
  type: ADD_TO_SNIPPET_VIEW_RESET,
})

export function addToSnippetViewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_TO_SNIPPET_VIEW: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_TO_SNIPPET_VIEW_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_TO_SNIPPET_VIEW_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case ADD_TO_SNIPPET_VIEW_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const GET_SNIPPET_VIEW = 'GET_SNIPPET_VIEW'
const GET_SNIPPET_VIEW_SUCCESS = 'GET_SNIPPET_VIEW_SUCCESS'
const GET_SNIPPET_VIEW_FAILURE = 'GET_SNIPPET_VIEW_FAILURE'
const GET_SNIPPET_VIEW_RESET = 'GET_SNIPPET_VIEW_RESET'

export const getSnippetViewAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.params,
    meta: {
      actionType: GET_SNIPPET_VIEW,
      url: `${process.env.apiUrl}/v0/projects/getProjectMapSnippetView`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added to snippet view',
    // hideNotification: false, // hide notification on error
  })
}

export const getSnippetViewResetAction = () => ({
  type: GET_SNIPPET_VIEW_RESET,
})

export function getSnippetViewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SNIPPET_VIEW: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SNIPPET_VIEW_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SNIPPET_VIEW_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SNIPPET_VIEW_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const DELETE_PI_FROM_SNIPPET_VIEW = 'DELETE_PI_FROM_SNIPPET_VIEW'
const DELETE_PI_FROM_SNIPPET_VIEW_SUCCESS = 'DELETE_PI_FROM_SNIPPET_VIEW_SUCCESS'
const DELETE_PI_FROM_SNIPPET_VIEW_FAILURE = 'DELETE_PI_FROM_SNIPPET_VIEW_FAILURE'
const DELETE_PI_FROM_SNIPPET_VIEW_RESET = 'DELETE_PI_FROM_SNIPPET_VIEW_RESET'

export const deletePiFromSnippetViewAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload: payload.body,
    meta: {
      actionType: DELETE_PI_FROM_SNIPPET_VIEW,
      url: `${process.env.apiUrl}/v0/projects/removeProjectMapSnippetViewPi?${stringify(payload.params)}`,
      method: 'PUT',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added to snippet view',
    // hideNotification: false, // hide notification on error
  })
}

export const deletePiFromSnippetViewResetAction = () => ({
  type: DELETE_PI_FROM_SNIPPET_VIEW_RESET,
})

export function deletePiFromSnippetViewReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_PI_FROM_SNIPPET_VIEW: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_PI_FROM_SNIPPET_VIEW_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_PI_FROM_SNIPPET_VIEW_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    case DELETE_PI_FROM_SNIPPET_VIEW_RESET: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
