import React, { useState } from 'react'
// import AllianceNonAllianceMap from './AllianceNonAllianceMap'
import ChoroplethMapDrillDown from './ShortlistedVsCandidatesMap'
import CompetitiveMap from './CompetitiveMap'

const MapViewSavedProjectResults = ({
  data, project_id, mapFullScreen, setMapFullScreen, projectFilters,
}) => {
  const [currentTab, setCurrentTab] = useState('shortlisted_vs_candidate')
  const tabs = [
    {
      title: 'Shortlisted Vs Candidates',
      key: 'shortlisted_vs_candidate',
    },
    {
      title: 'Competitive Environment',
      key: 'competitive_environment',
      // additionalClass: 'disabled',
    },
  ]

  const renderTab = () => {
    switch (currentTab) {
      case 'shortlisted_vs_candidate': return <ChoroplethMapDrillDown data={data} project_id={project_id} mapFullScreen={mapFullScreen} setMapFullScreen={setMapFullScreen} projectFilters={projectFilters} />
      case 'competitive_environment': return <CompetitiveMap data={data} flag="competitive" project_id={project_id} mapFullScreen={mapFullScreen} setMapFullScreen={setMapFullScreen} projectFilters={projectFilters} />
      default: return <div>Under development </div>
    }
  }

  return (
    <div className="map-view-main-div">
      {!mapFullScreen
      && (
        <div className="details-page-tabs country-project-tabs-list-tab">
          <div className="searbar-tabs">
            {tabs.map((tabItm, index) => (
              <div
                className={`tagsbutton ${currentTab === tabItm.key ? 'active' : ''} ${tabItm.additionalClass}`}
                role="presentation"
                onClick={() => {
                  if (currentTab !== tabItm.key) {
                    setCurrentTab(tabItm.key);
                  }
                }}
                title={tabItm.key}
                key={index}
              >
                <div className="tab-title">
                  {tabItm.title}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="map-view-map-section">
        {renderTab()}
      </div>
    </div>
  )
}

export default MapViewSavedProjectResults;
